/**
 * This component is a customized version of the EntityTagPicker from the Backstage repository.
 * It allows for an optional filtered default view via the `initialFilter` prop.
 *
 * Original Path: `@backstage/plugin-catalog-react/src/components/EntityTagPicker/EntityTagPicker.tsx`
 *
 * Modifications:
 * - Added `initialFilter` prop to provide the option to filter the default view of tags.
 *
 * Purpose:
 * - To provide an enhanced version of the EntityTagPicker with support for pre-filtered tag selections.
 *
 * Maintainer Notes:
 * - Ensure that the `initialFilter` prop is properly passed if you wish to pre-select or filter tags by default.
 */
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import {
  EntityAutocompletePicker,
  EntityTagFilter
} from '@backstage/plugin-catalog-react'

export type CatalogReactEntityTagPickerClassKey = 'input'

export type EntityTagPickerProps = {
  initialFilter?: string[]
  showCounts?: boolean
}

const useStyles = makeStyles({ input: {} }, { name: 'CatalogReactEntityTagPicker' })
export const CustomEntityTagPicker = (props: EntityTagPickerProps) => {
  const classes = useStyles()
  const initialFilter = props.initialFilter ?? []

  return (
    <EntityAutocompletePicker
      label="Tags"
      name="tags"
      path="metadata.tags"
      Filter={EntityTagFilter}
      showCounts={props.showCounts}
      InputProps={{ className: classes.input }}
      initialSelectedOptions={initialFilter}
    />
  )
}
