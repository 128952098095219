import React from 'react'
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react'
import { FieldValidation } from '@rjsf/utils'
import { FormControl, TextField } from '@material-ui/core'
import { ApiHolder } from '@backstage/core-plugin-api'
import { catalogApiRef } from '@backstage/plugin-catalog-react'

export function ComponentNameTextField({
  rawErrors,
  required,
  formData,
  schema,
  onChange
}: FieldExtensionComponentProps<string>) {
  const { title, description } = schema
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <TextField
        label={title}
        helperText={description}
        value={formData}
        onChange={e => onChange(e.target?.value)}
      />
    </FormControl>
  )
}

export async function validateComponentName(
  value: string,
  validation: FieldValidation,
  context: {
    apiHolder: ApiHolder
  }
) {
  const { apiHolder } = context
  if (!/^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/g.test(value) || value === undefined) {
    validation.addError(
      `Should only use alphanumeric characters in lower case delimited by dashes`
    )
    return
  }

  const catalogApi = apiHolder.get(catalogApiRef)
  if (!catalogApi) {
    validation.addError(
      `Cannot check name uniqueness due to an internal error. Please, try again or contact DevX team`
    )
    return
  }

  const { items } = await catalogApi.getEntities({
    filter: {
      kind: 'Component',
      'metadata.name': value
    }
  })

  if (items.length > 0) {
    validation.addError(
      `A component with this name exists already. The name must be unique across the whole catalog`
    )
  }
}
